
.image-grid .image-div {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.image-grid .image-div>.overlay {
    position: absolute;
    top: 0;
    left: -100%;
    background-color: rgba(5, 5, 5, 0.616);
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    opacity: 0;
    color: white; 
}
.image-grid .image-div .text{
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    font-weight: 600;
    display: inline-block;
    clear: none;
    margin: 0px 120px 40px 40px;
    padding: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
    background: transparent;
    position: relative;
    z-index: 2 !important;
    font-family: "Roboto Serif", serif;
    position: relative;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
}
.image-grid .image-div .text>.icon {
    margin-left: 0.5rem !important;
    cursor: pointer;
}
.image-grid .image-div img {
    max-width: 100%;
}
.image-grid .image-div:hover .overlay {
    opacity: 1;
    left: 0;
}