.product-filter .button-group {
    gap: 2rem;
    flex-wrap:wrap;
}
.product-filter .button-group .button {
    padding: 0.5rem 1.61em;
    box-shadow: none !important;
    letter-spacing: 0.2em;
    font-weight: 300;
    text-transform: uppercase;
    border-radius: 1.61em !important;
    -moz-border-radius: 1.61em;
    -webkit-border-radius: 1.61em;
    font-size: 0.866em;
    background-color: #fff;
    border-color: #bbb;
    color: #0e0e12;
    transition: 0.4s ease-in-out;
}
.product-filter .button-group .button:hover {
    border-color: #e947a5;
    color: #e947a5;
}
.product-filter .button-group .button.active {
    border-color: #e947a5;
    color: #e947a5;
}