.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.padding-right-100 {
  padding-right: 1rem !important;
}
.background-transparent {
  background-color: transparent !important;
}
.nav-link {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.nav-item>.label {
  padding-top: 1rem !important;
}
.ul {
  padding-left: 1.8em;
  margin-bottom: 0;
  list-style-type: none;
  margin-top: 0em;
  line-height: 2.4em;
}
.ul .li {
  position: relative;
  word-wrap: break-word;
  transition: all 0.3s ease;
}
.ul .li::before {
  color: #271f71;
  background-color: #fcd63c;
  content: '';
    font-family: fontello;
  font-size: 11px;
    left: -26px;
    top: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: right;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    word-wrap: break-word;
    position: absolute;
}
.ul .li.white {
  color: white !important;
}
.ul .li:hover {
  color: #f1d666 !important;
}
.item-box-container {
  padding: 4rem 2rem 2rem 4rem;
}
.item-box {
  padding: 0rem 2rem 2rem 0rem;
  height: 100%;
}
.item-box>.inner {
  padding: 2em 1.9em 1.5em;
  text-align: center;
  height: 100%;
  color: #23242c;
  background-color: rgba(255, 255, 255, 0.15);
  transition: all 0.3s ease;
}
.item-box>.inner>.icon {
  color: white;
  margin-bottom: 2rem;
}

.item-box>.inner>.text {
  margin-bottom: 2rem;
  transition: all 0.1s ease;
  color: #0e0e12;
  display: block;
  font-size: 0.866em;
  font-weight: 600;
  letter-spacing: 0.24em;
  font-family: "Roboto Serif", serif;
  font-style: normal;
  line-height: 1.352em;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.item-box>.inner:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.item-box>.inner:hover .text {
  color: #fff;
}

.bg-gray {
  background-color: #e5e6e7 !important;
}

.nav-link-custom {
  text-decoration: none;
  color: inherit;
}