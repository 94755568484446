.footer {
    margin-top: 3rem;
}

.footer .wrapper {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    max-width: 600px;
    margin: 2rem auto;
}

.footer .wrapper li {
    transition: all ease 0.3s;
    display: inline-block;
}

.footer .wrapper li:hover {
    color: hsl(0, 0%, 44%);
}

.footer .wrapper li .link {
    text-transform: uppercase;
    font-size: 0.75rem;
    text-decoration: none;
    color: inherit;
}

.footer .wrapper li .fab {
    margin-right: 8px;
    color: inherit;
}

.footer .text {
    color: hsl(0, 0%, 63%);
    text-align: center;
}
.footer .separador {
    background-color: var(--faded-gray);
    height: 2px;
    max-width: 30px;
    margin: 15px auto 20px;
}