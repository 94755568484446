.contact-form-left {
    color: #ffffff;
    background: linear-gradient(to right, #04caf7 0%, #47d6e8 100%);
    padding: 5.33em 5.33em 6.4em;
    z-index: 2;
    position: relative;
}

.contact-form-left>.inner {
    box-sizing: border-box;
    width: 100%;
}

.contact-form-left>.inner .item {
    position: relative;
    text-align: center;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
}
.contact-form-left>.inner .item .icon {
    padding-right: 1em;
    color: #fff;
    transition: all ease .3s;
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 8rem;
    float: left;
}
.contact-form-left>.inner .item .title {
    color: #0e0e12;
    display: block;
    text-align: left;
    font-size: 0.866em;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 0.24em;
    margin-top: 0;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    margin: 0;
    font-style: normal;
    margin-bottom: 0.75rem;
}
.contact-form-left>.inner .item .description {
    color: #ffffff;
    display: block;
    text-align: left;
    font-size: 1.066em;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: 0.02em;
    margin-top: 4px;
    margin: 0;
    text-decoration: none;
    transition: all 0.3s ease;
}
.contact-form-left>.inner .item .description:hover {
    color: #000;
}